
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi:content-copy",
    },
    beforeCopyText: {
      type: String,
      default: "Click to copy",
    },
    afterCopyText: {
      type: String,
      default: "Copied",
    },
    value: {
      type: String,
      required: true,
    },
    iconSize: {
        type: String,
        default: '24px',
    }
  },
  data() {
    return {
      tooltipText: "",
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.tooltipText = this.beforeCopyText;
    },
    async copyValue() {
      await navigator.clipboard.writeText(this.value);
      this.tooltipText = this.afterCopyText;
    },
  },
};
