
import moment from "moment-timezone";

export default {
  props: {
    stock: {
      type: Object,
      required: true,
    },
  },
  computed: {
    regularMarketTime() {
      let temp = this.realtimeData?.regularMarketTime;
      if (temp) {
        return moment(temp).tz("Asia/Kolkata").format("MMM D, HH:mm [GMT] Z");
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      realtimeData: {},
      // realtimeData: {
      //   currency: "INR",
      //   language: "en-US",
      //   region: "US",
      //   quoteType: "EQUITY",
      //   typeDisp: "Equity",
      //   quoteSourceName: "Delayed Quote",
      //   triggerable: true,
      //   customPriceAlertConfidence: "HIGH",
      //   marketState: "CLOSED",
      //   exchange: "NSI",
      //   shortName: "BAJAJ FINSERV LTD",
      //   longName: "Bajaj Finserv Ltd.",
      //   exchangeTimezoneName: "Asia/Kolkata",
      //   exchangeTimezoneShortName: "IST",
      //   gmtOffSetMilliseconds: 19800000,
      //   market: "in_market",
      //   esgPopulated: false,
      //   regularMarketChangePercent: 0.035724416,
      //   regularMarketPrice: 1540.25,
      //   firstTradeDateMilliseconds: "2002-08-12T03:45:00.000Z",
      //   priceHint: 2,
      //   regularMarketTime: "2023-09-29T10:00:00.000Z",
      //   regularMarketDayHigh: 1552.95,
      //   regularMarketDayRange: { low: 1532.05, high: 1552.95 },
      //   regularMarketDayLow: 1532.05,
      //   regularMarketVolume: 1109755,
      //   regularMarketPreviousClose: 1545.7,
      //   fullExchangeName: "NSE",
      //   regularMarketOpen: 1548,
      //   fiftyTwoWeekLowChange: 325.25,
      //   fiftyTwoWeekLowChangePercent: 0.2676955,
      //   fiftyTwoWeekRange: { low: 1215, high: 1817.9 },
      //   fiftyTwoWeekHighChange: -277.65002,
      //   fiftyTwoWeekHighChangePercent: -0.15273118,
      //   fiftyTwoWeekLow: 1215,
      //   fiftyTwoWeekHigh: 1817.9,
      //   tradeable: false,
      //   cryptoTradeable: false,
      //   sourceInterval: 15,
      //   exchangeDataDelayedBy: 15,
      //   symbol: "BAJAJFINSV.NS",
      // },
    };
  },
  mounted() {
    this.$socket.emit("addStock", this.stock.symbol);
    this.$socket.on(this.stock.symbol, (val) => {
      this.realtimeData = val;
    });
  },
  beforeDestroy() {
    this.$socket.emit("removeStock", this.stock.symbol);
  },
};
